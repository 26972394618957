.slider__container .slide img{
    width: 100%;
    height: 600px;
}

.slider__container .slide {
    position: relative;
    z-index: -20;
    overflow: hidden;
}

.slider__container .slide .veg-background-img {
    filter: blur(24px);
}

.slider__container .slide .veg-banner-img {
    position: absolute;
    z-index: 10;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
}

@media only screen and (max-width: 992px) {
    .slider__container .slide img{
        height: 400px;
    }
}

@media only screen and (max-width: 768px) {
    .slider__container .slide img{
        height: 300px;
    }
}

@media only screen and (max-width: 576px) {
    .slider__container .slide img{
        height: 250px;
    }
}