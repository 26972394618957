.wallet .payment__methods {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.wallet .payment__methods button{
    width: 250px;
    background-color: white;
    font-size: 14px;
    border-radius: 30px;
    padding: 5px;
    color: #212245;
    border: 1px solid #212245;
}

.wallet .card__details {
    display: flex;
    flex-direction: column;
    gap: 15px;
}