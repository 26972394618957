.category__item {
    position: relative;
    padding: 30px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.4s;
}

.category__item .category__img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.category__item .category__img img {
    width: 100px;
    height: 70px;
}

.category__item h6 {
    text-align: center;
    padding-top: 16px;
    color:#212245;
}

@media only screen and (max-width: 768px) {
    .category__img {
        padding: 20px 15px;
    }

    .category__item h6 {
        font-size: 0.8rem;
    }
}