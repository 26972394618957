.personal__info {
    border: 1px solid #c5c5c5;
    padding: 20px;
    border-radius: 15px;
}

.personal__info .header {
    font-size: 1.2rem;
    font-weight: 500;
}

.personal__info .title {
    font-size: 0.9rem;
    color: #696969;
}

.personal__info .label {
    color: #212245;
}

.edit__button {
    border: none;
    background-color: white;
    color: #212245;
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 350px) {
    .edit__button {
        font-size: 0.9rem;
    }
}