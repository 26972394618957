.footer {
    padding-top: 50px;
    padding-bottom: 30px;
    background: #53b17526;
}

.footer__logo img{
    width: 100px;
    margin-left: -15px;
    margin-bottom: 5px;
}

.footer__logo h5{
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 15px;
}

.footer__logo p{
    font-size: 0.9rem;
    color: #333;
    line-height: 28px;
}

.footer__title {
    font-weight: 600;
    font-size: 1.1rem;
    color: #212245;
}

.footer .list__item {
    background: transparent !important;
}

.footer .list__item a {
    text-decoration: none;
    color: #212245;
    font-size: 0.9rem;
}

.footer .list__item span {
    font-weight: 600;
    font-size: 1rem;
    color: #212245;
}

.footer .list__item p {
    color: #212245;
}

.footer .newsletter {
    padding: 7px 15px;
    border: 1px solid #212245;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.footer .newsletter input {
    background: transparent;
    border: none;
    outline: none;
}

.footer .newsletter input:focus {
    outline: none;
}

.footer .newsletter input::placeholder {
    color: #212245
}

.footer .newsletter span {
    background: #53B175;
    padding: 5px 20px;
    border-radius: 5px;
}

.footer .newsletter .send__icon {
    color: #fff;
}

.copyright__text {
    font-size: 0.8rem;
    color: #53B175;
}

.footer .social__links .social__icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: white;
    background-color: #53B175;
    display: grid;
    align-content: center;
    justify-content: center;
    transition-duration: .5s;
}

@media only screen and (max-width: 992px) {
    .footer .newsletter input {
        padding: 7px;
        font-size: 0.7rem;
    }

    .footer .newsletter span {
        padding: 4px 10px;
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 768px) {
    .footer__title {
        font-size: 0.8rem;
    }

    .footer .list__item span{
        font-size: 0.8rem;
    }

    .footer .list__item p {
        font-size: 0.7rem;
        margin-bottom: 0;
    }

    .footer .newsletter input {
        padding: 10px 7px;
        font-size: 0.7rem;
    }

    .footer .newsletter span {
        padding: 4px 10px;
    }

}

@media only screen and (max-width: 576px) {
    .copyright__text {
        text-align: center;
    }

    .footer .social__links {
        justify-content: center !important;
    }

    .footer .social__links p {
        font-size: 0.8rem;
    }

    .footer .social__links span {
        padding: 4px;
    }

    .footer .social__links span a {
        font-size: 0.7rem;
    }
}