.header {
    width: 100%;
    height: 90px;
    line-height: 60px;
    padding-top: 12px;
}

.sticky__header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 90px;
    padding-top: 12px;
    line-height: 60px;
    z-index: 1000;
    background-color: white;
}

.header__logo img{
    width: 45px;
    object-fit: contain;
}

.header__logo h5 {
    font-weight: 600;
    font-size: 1rem;
    color: #53B175;
    margin-bottom: 12px;
}

.header__menu a {
    font-weight: 600;
    font-size: 1rem;
    color: #212245;
    transition: .3s;
    text-decoration: none;
}

.header__menu a:hover{
    color: #53B175;
}

.active__header__menu {
    color: #53B175 !important
}

.cart__icon,
.user__icon,
.menu__icon {
    font-size: 1.3rem;
    color: #212245;
    cursor: pointer;
}

.cart {
    position: relative;
}

.cart__badge {
    position: absolute;
    top: 15px;
    right: -10px;
    background: #df2020;
    color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: .8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile__menu {
    font-weight: 600;
    display: none;
}

.header__shrink {
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 99999;
    box-shadow: 5px 5px 15px -5px #53b17526;
}

@media only screen and (max-width:992px) {

    .header__logo img {
        width: 40px;
    }

    .header__logo h5 {
        font-size: 0.8rem;
    }

    .navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.425);
        z-index: 99;
        display: none;
    }

    .header__menu {
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background: #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 100;
        line-height: 20px;
    }

    .header__menu a {
        font-size: 0.9rem;
    }

    .mobile__menu {
        display: block;
    }

    .cart__icon,
    .user__icon,
    .menu__icon {
        font-size: 1rem;
    }

    .show__menu  {
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    
    .header {
        width: 100%;
        height: 50px;
        line-height: 50px;
    }
}