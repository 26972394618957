.order__card {
    display: flex;
    flex-direction: column;
    border: 1px solid #c5c5c5;
    border-radius: 8px;
}

.order__card .delivery__method {
    color: #696969;
}

.order__card .delivery__date {
    font-weight: 600;
}

.order__card__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid lightgray;
}

.order__card__row img {
    width: 35px;
    height: 35px;
    object-fit: cover;
}

.order__card__row .quantity {
    font-size: 0.9rem;
    color: #696969;
}

.order__card__row .total__price {
    font-weight: 500;
}