.search__widget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 7px 15px;
    border: 1px solid #53b17526;
    border-radius: 5px;
    cursor: pointer;
}

.sorting__widget {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 7px 15px;
    border: 1px solid #53b17526;
    border-radius: 5px;
    cursor: pointer;
}

.sorting__widget select{
    width: 100%;
    border: none;
    background-color: white;
    -webkit-appearance: none; 
    color: #212245;
}

.sorting__widget select:focus {
    outline: none;
}

.search__widget input {
    border: none;
}

.search__widget input:focus {
    outline: none;
}

@media only screen and (max-width:768px) {
    .search__widget {
        width: 100%;
        margin-bottom: 20px;
    }

    .search__widget input {
        width: 100%;
        font-size: 0.8rem;
    }

    .sorting__widget select {
        width: 100% !important;
        font-size: 0.8rem;
    }
}