.product__detail .product__content {
    padding-left: 50px;
}

.product__detail .images .image__container{
    width: 50%;
}

.product__detail .images .image__container img{
    object-fit: contain;
    width: 70%;
}

.product__detail .product__content .price {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 0;
}

.product__detail .product__content .price span {
    font-size: 2rem;
}

.product__detail .product__content .category {
    font-weight: 500;
    color: #212245;
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.product__detail .product__content .category span {
    padding: 5px 10px;
    background: #53b17526;
    color: #212245;
    border-radius: 5px;
}

.addToCart__btn {
    border: none;
    padding: 7px 25px;
    background: #53B175;
    color: #fff;
    border-radius: 5px;
    font-size: 0.9rem;
}

.product__detail .tabs {
    border-bottom: 1px solid #53b17526;
    margin-top: 30px;
}

.product__detail .tabs h6 {
    cursor: pointer;
}

.product__detail .description {
    padding: 30px 0px;
}

.product__detail .description p {
    line-height: 30px;
    color: #212245b6;
}

.product__detail .tabs .tab__active {
    color: #53B175;
}

.review__form form {
    width: 60%;
    margin: auto;
    background: #53b17526;
    padding: 20px;
    border-radius: 5px;
}

.review__form .form__group {
    margin-bottom: 30px;
}

.review__form .form__group input,
.review__form .form__group textarea {
    width: 100%;
    background: transparent;
    padding: 7px 20px;
    border: none;
    border-bottom: 1px solid #2122454c;
}

.review__form .form__group input:focus,
.review__form .form__group textarea:focus {
    outline: none;
}

.review__form .user__name {
    font-weight: 600;
    color: #212245;
}

.review__form .user__email {
    font-size: 0.8rem;
}

.review__form .feedback__text {
    color: #212245b6;
}

.related__product-title {
    font-size: 1.4rem;
}

@media only screen and (max-width:768px) {
  .product__detail .product__content .title {
    font-size: 1.2rem;
  }

  .product__detail .product__content .price,
  .product__detail .product__content .price span {
    font-size: 1rem;
  }

  .product__detail .product__content .category, 
  .product__detail .product__content .category span {
    font-size: 0.8rem !important;
  }

  .product__detail .tabs h6 {
    font-size: 0.9rem;
  }

  .product__detail .tabs .description p,
  .review__form .user__name,
  .review__form .feedback__text {
    font-size: 0.8rem;
  }

  .review__form .user__email {
    font-size: 0.7rem;
  }

  .review__form form {
    width: 100% !important;
    margin-top: 50px;
  }

  .review__form  .form__group input::placeholder,
  .review__form  .form__group textarea::placeholder {
    font-size: 0.8rem;
  }

  .related__product-title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width:576px) {
    .product__detail .images {
        display: flex;
        direction: row;
    }

    .product__detail .preview__img {
        margin-bottom: 20px;
        width: 70%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .product__detail .preview__img img {
        justify-content: center;
    }

    .product__detail .product__content {
        padding-left: 20px;
    }
}