.address__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0px 0px 10px lightgrey;
    padding: 20px;
}

.address__card .left__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.address__card .name,
.address__card .preferred__label {
    font-size: 0.9rem;
    color: #696969;
}

.address__card .address {
    font-size: 0.9rem;
}