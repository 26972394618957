.delivery__addresses {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.delivery__addresses button{
    border: none;
    background-color: white;
    color: #212245;
    text-decoration: underline;
    cursor: pointer;
}

.delivery__addresses__list {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.add__addresses__forms {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add__addresses__forms .input__field {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.add__addresses__forms .input__field span {
    font-size: 0.9rem;
    font-weight: 500;
}

.add__addresses__forms .input__field input{
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    outline-color: #53B175;
}

.contact__note,
.required__field__note {
    color: #696969;
    font-size: 0.7rem !important;
}

.preferred__box span{
    font-size: 0.8rem;
}

.add__addresses__forms .cancel__and__save__btns {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
}

.add__addresses__forms .cancel__and__save__btns .save__btn {
    border: none;
    padding: 7px 25px;
    background: #53B175;
    color: #fff;
    border-radius: 25px;
    font-size: 0.9rem;
    text-decoration: none;
}