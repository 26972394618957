.product__item {
    width: 100%;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px #c5c5c5;
    transition: 0.3s;
}

.product__item a {
    text-decoration: none;
}

.product__item img {
    width: 100px;
    height: 100px;
    border-radius: 15px 15px 0px 0px;
    object-fit: contain;
}

.product__item .info {
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.product__item .info .name {
    color: #212245;
    font-size: 1.2rem;
    font-weight: 500;
    padding-top: 10px;
}

.product__item .info  .price {
    color: #53B175;
    font-size: 1rem;
    font-weight: 500;
}

.product__item .counter {
    position: relative;
}

.product__item .counter .add {
    position: absolute;
    right: 10px;
}

.addToCart__icon {
    height: 25px;
    width: 25px;
    cursor: pointer;
    color: #53B175;
}

.removeFromCart__icon  {
    height: 25px;
    width: 25px;
    background: #53B175;
    color: white;
    border-radius: 50%;
    border: 1px solid #53B175;
    cursor: pointer;
}

.product__item .quantity__counter {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 576px) {
    .product__item {
        padding: 20px;
    }

    .product__item .info .name {
        font-size: 1.1rem;
    }

    .product__item .info  .price {
        font-size: 0.9rem;
    }
    
    .product__item .counter .add {
        right: 5px;
    }

    .product__item .quantity__counter {
        right: 5px;
    }
}