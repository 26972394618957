.cart__product__details {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cart__product__details .name {
    font-size: 1.2rem;
    font-weight: 500;
    color: #212245;
}

.cart__product__details .price {
    font-size: 0.9rem;
    color: #696969;
}

.cart__product__details .left__counter {
    display: none;
    gap: 10px;
}

.cart__items__item .middle__counter {
    display: flex;
    gap: 10px;
}

.removeFromCart__btn {
    width: fit-content;
    border: 1px solid #808080;
    padding: 5px;
    background: #fff;
    color: #808080;
    border-radius: 5px;
    font-size: 0.7rem;
    cursor: pointer;
}

.checkout__btn {
    display: flex;
}

.checkout__btn button a {
    text-decoration: none;
    color: #fff;
}

.checkout__btn button a:hover {
    color: #fff;
}

.cart .subtotal span{
    color: #53B175;
    font-size: 1.5rem;
}

.cart__items__title {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
}

.cart__items__title span {
    color: #212245;
    font-size: 1rem;
}

.cart__items__item {
    margin: 10px 0;
    color: #212245;
}

.cart__items__item img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.cart hr {
    height: 2px;
    background-color: lightgray;
    border: none;
}

@media only screen and (max-width: 576px) {
    .cart__product__details .name {
        font-size: 1rem;
    }

    .cart__product__details .price {
        font-size: 0.7rem;
    }
    
    .removeFromCart__btn {
        font-size: 0.6rem;
    }

    .cart .subtotal span{
        font-size: 1.2rem;
    }

    .cart__items__title span {
        font-size: 1rem;
    }

    .checkout__btn {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .checkout__btn button {
        width: fit-content;
        font-size: 0.8rem;
    }

    .cart__items__title {
        display: grid;
        grid-template-columns: 3fr 1fr;
    }

    .cart__product__details .left__counter {
        display: flex;
    }

    .cart__items__item .middle__counter {
        display: none;
    }

    .cart__items__title .quantity {
        display: none;
    }

    .cart .subtotal {
        font-size: 0.9rem;
    }

    .cart .checkout__info {
        font-size: 0.9rem;
    }
}