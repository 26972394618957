ul {
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin: 0px;
    padding: 5px 0px;
    padding-left: 0px !important;
}

li {
    margin: 0px 0px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    cursor: pointer;
}

.account__menu {
    border-right: 1px solid #d9d9d9;
}

.account__menu a {
    text-decoration: none;
    color: #212245;
}

.selected__bg {
    background-color: #53B17526;
    border-left: 2px solid #53B175;
}

.menu__option {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 768px) {
    .account__menu {
        display: none;
    }
}
