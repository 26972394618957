.login__popup {
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background-color: #00000090;
    display: grid;
}

.login__popup form {
    place-self: center;
    width: max(23vw, 330px);
    color: #808080;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 30px;
    border-radius: 8px;
    font-size: 0.9rem;
}

.login__popup__bottom {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.login__popup__title {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    color: #212245;
}

.login__popup .close {
    margin-top: -10px;
}

.login__popup .close span{
    font-size: 1.5rem;
    color: black;
    cursor: pointer;
}

/* .login__popup__inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
} */

.login__popup__bottom input {
    outline: none;
    border: 1px solid #c5c5c5;
    padding: 10px;
    border-radius: 4px;
}

.login__popup__button {
    border: none;
    padding: 10px;
    border-radius: 4px;
    color: white;
    background-color: #53B175;
    font-size: 1rem;
    cursor: pointer;
}

.login__popup__condition {
    display: flex;
    align-items: start;
    gap: 8px;
    margin-top: -15px;
}

.login__popup__condition input {
    margin-top: 5px;
}

.login__popup p span {
    color: #53B175;
    font-weight: 500;
    cursor: pointer;
}

.login__popup__divide {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.login__popup__divide span{
    color: #808080;
}

.login__popup__divide .line {
    flex: 1;
    height: 1px;
    background-color: #808080;
}

.login__popup__bottom .forgotPwd {
    color: #53B175;
}

.login__social__icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: black;
    background-color: lightgray;
    display: grid;
    align-content: center;
    justify-content: center;
    transition-duration: .5s;
}