.checkout__form input{
    margin-bottom: 15px;
    width: 100%;
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    outline-color: #53B175;
}

.checkout__form .mutli__fields {
    display: flex;
    gap: 10px;
}

.checkout__bill {
    background: #53b17526;
    padding: 30px;
}

.checkout__total {
    border-top: 1px solid #ddd;
    padding-top: 30px;
}

.checkout__form button a {
    text-decoration: none;
    color: #fff;
}

.step__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    width: 50%;
}
  
.step__col {
    display: flex;
    align-items: center;
    text-align: center;
}

.step__circle__active {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #53b17526;
    border: 1px solid #53B175;
    color: #53B175;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    margin-right: 10px;
}

.step__label__active {
    color: #53B175;
    font-weight: 600;
}

.step__circle__completed {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #53B175;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-right: 10px;
}

.step__label__completed {
    color: #53B175;
}

.step__circle__pending {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #d9d9d9;
    color: #808080;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-right: 10px;
}

.step__label__pending {
    color: #808080;
}

.step__line__completed {
    height: 2px;
    background-color: #53B175;
    width: 100%;
}

.step__line__pending {
    height: 2px;
    background-color: #808080;
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .step__row {
        width: 75%;
    }
}

@media only screen and (max-width: 768px) {
    .checkout__bill h6{
        font-size: 0.9rem;
    }

    .checkout__bill {
        margin-top: 40px;
    }

    .step__row {
        width: 90%;
        gap: 20px;
    }

    .step__circle__active, .step__circle__completed, .step__circle__pending {
        width: 25px;
        height: 25px;
        font-size: 0.8rem;
    }

    .step__col span {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 576px) {
    .step__row {
        width: 90%;
        gap: 15px;
    }

    .checkout__form input::placeholder {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 350px) {
    .step__row {
        width: 100%;
        gap: 10px;
    }

    .step__circle__active, .step__circle__completed, .step__circle__pending {
        width: 20px;
        height: 20px;
        font-size: 0.5rem;
        margin-right: 3px;
    }

    .step__col span {
        font-size: 0.7rem;
    }
}