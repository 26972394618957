.cart__item {
    padding-bottom: 10px;
    border-bottom: 1px solid #53b17526;
}

.cart__item-info img {
    width: 35px;
    height: 35px;
    object-fit: cover;
}

.cart__product-title {
    font-size: 0.8rem;
    font-weight: 600;
}

.cart__product-price {
    font-size: 0.8rem;
}

.cart__product-price span {
    font-size: 0.9rem;
    font-weight: 600;
    color: #53B175;
}

.quantity__btn {
    background: #53b17526;
    padding: 2px 5px;
    border-radius: 5px;
}

.add__btn,
.subtract__btn {
    cursor: pointer;
}

.delete__btn {
    font-size: 1.1rem;
    font-weight: 600;
}