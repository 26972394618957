.main__nav {
    color: #212245;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
}

.sub__nav {
    color: #212245;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.account__container {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px lightgrey;
    border-radius: 15px;
}

.account__container a {
    text-decoration: none;
    color: #212245;
}

.account__purchase__history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #53B17526;
    padding: 20px;
    border-radius: 15px;
}

.account__header {
    font-weight: 500;
}

.account__wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #53B17526;
    padding: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.account__subheader {
    color: #696969;
    font-weight: 400;
}

.wallet__content {
    display: flex;
    margin: 20px;
    gap: 20px;
}

.default__card {
    display: flex;
    flex-direction: column;
    width: 288px;
    background-color: #1A1F85;
    color: white;
    padding: 15px;
    border-radius: 8px;
    gap: 5px;
}

.payment__icon {
    width: 36px;
}

.default__card__title {
    font-size: 12px;
}

.card__number, .card__holder__name, .card__expiry__date {
    font-size: 14px;
}

.add__payment__method {
    width: 250px;
    height: 160px;
    border: 1px solid lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.add__payment__method button {
    background-color: white;
    font-size: 14px;
    border-radius: 30px;
    padding: 5px;
    width: 90%;
    color: #212245;
    border: 1px solid #212245;
}

.account__container .account__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #53B17526;
    padding: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.account__info__content .email__and__phone {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid lightgray;
}

.account__info__content .email__and__phone .email {
    width: 50%;
}

.account__info__content .email__and__phone .phone {
    width: 50%;
    border-left: 1px solid lightgrey;
}

.account__info__content .addresses {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.account__view__addresses-btn {
    height: 32px;
    margin-right: 20px;
    padding: 5px 10px;
    background-color: white;
    font-size: 14px;
    border-radius: 30px;
    color: #212245;
    border: 1px solid #212245;
}

.account__info__detail__header {
    font-weight: 600;
    color: #212245;
}

.account__info__detail__label {
    color: #212245;
}

@media only screen and (max-width: 768px) {
    .wallet__content {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 350px) {
    .account__info__content .email__and__phone {
        display: flex;
        flex-direction: column;
    }

    .account__info__content .email__and__phone .email {
        width: 100%;
        border-bottom: 1px solid lightgray;
    }

    .account__info__content .email__and__phone .phone {
        width: 100%;
        border-left: none;
    }

    .account__info__content .addresses {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .account__view__addresses-btn {
        margin: 20px;
    }
}