.order__details .header {
    font-size: 1.4rem;
    font-weight: 600;
}

.order__number {
    color: #696969;
    font-weight: 400;
}

.delivery__details {
    display: flex;
    flex-direction: column;
    border: 1px solid #c5c5c5;
    padding: 20px;
    gap: 20px;
    border-radius: 8px;
}


.delivery__details img {
    width: 24px;
}

.delivery__details hr {
    margin: 0px;
}

.delivery__details .delivery__method {
    background-color: #53B17526;
    color: #53B175;
    padding: 5px;
    width: max-content;
    border-radius: 5px;
}

.delivery__details .title {
    font-weight: 500;
}

.delivery__details .subtitle,
.delivery__details .label {
    color: #696969;
    font-size: 0.9rem
}

@media only screen and (max-width: 768px) {
    .order__details__container {
        gap: 30px;
    }
}

@media only screen and (max-width: 576px) {
    .order__details .header {
        font-size: 1.2rem;
    }
}