.purchase__history a {
    text-decoration: none;
}

.order__history__content {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px #c5c5c5;
    border-radius: 15px;
}

.order__history__summary {
    display: flex;
    gap: 0px;
}

.order__history__summary .date {
    font-size: 1rem;
    font-weight: 500;
    color: #212245;
}

.order__history__summary .order__number {
    font-size: 1rem;
    color: #696969;
}

.order__history__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #53B17526;
    padding: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.order__history__header button {
    height: 32px;
    padding: 5px 10px;
    background-color: white;
    font-size: 14px;
    border-radius: 30px;
    color: #212245;
    border: 1px solid #212245;
}

.order__history__details {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
}

.order__history__details .delivery__method {
    color: #696969;
    font-size: 14px;
}

.order__history__details .delivery__date {
    color: #212245;
    font-size: 18px;
    font-weight: 600;
}

.order__history__details .number__of__items {
    padding-top: 15px;
    color: #696969;
    font-size: 14px;
}

.order__history__list {
    margin-bottom: 0px !important;
}

.order__history__list__item {
    display: inline-block;
}

.order__history__list__item:nth-of-type(1n+7) {
    display: none;
}

.order__history__item {
    position: relative;
}

.order__history__item img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.order__history__item .order__badge {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    background: white;
    color: #212245;
    border: 1px solid #212245;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: .7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .order__history__summary .date,
    .order__history__summary .order__number  {
        font-size: 0.9rem;
    }

    .order__header button {
        font-size: 0.8rem;
    }

    .order__history__summary {
        display: flex;
        flex-direction: column;
    }    
}

@media only screen and (max-width: 576px) {
    .order__history__header button {
        height: 45px;
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 350px) {
    .order__history__summary .date,
    .order__history__summary .order__number  {
        font-size: 0.8rem;
    }
}