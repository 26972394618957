.app__download {
    margin: auto auto;
    margin-top: 120px;
    text-align: center;
}

.app__download__preview {
    max-width: 80%;
    height: auto;
    margin-top: -120px;
}

.app__download__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    gap: 20px;
}

.app__download__right h5 {
    color: #212245;
}

.app__download__right span {
    font-size: 0.9rem;
    color: #333;
    line-height: 28px;
}

.app__download__platform {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    gap: 20px;
}

.app__download__platform img {
    max-width: 180px;
    transition: 0.5s;
    cursor: pointer;
}

.app__download__platform img:hover {
    transform: scale(1.05);
}

@media only screen and (max-width: 992px) {
    .app__download__preview {
        margin-top: -100px;
    }
}

@media only screen and (max-width: 768px) {
    .app__download__right {
        gap: 10px;
        /* padding-top: 20px; */
    }
}

@media only screen and (max-width: 576px) {
    .app__download__preview {
        margin-top: -100px;
    }

    .app__download__platform {
        display: flex;
        flex-direction: column;
    }

    .app__download__right {
        padding-top: 20px;
    }
}