.payment__container {
    background-color: #f4f4f4;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.payment .credit__card {
    align-items: center;
    background-color: #53b17526;
    border: 1px solid #53b175;
    padding: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.credit__card img {
    height: 30px;
}

.credit__card .secure__text {
    color: #808080;
    font-size: 0.9rem;
}

.credit__card__form {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.credit__card__form input::placeholder {
    color: #808080;
}

.credit__card__form input {
    margin-bottom: 15px;
    width: 100%;
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    outline-color: #53B175;
}

.credit__card__form .mutli__fields {
    display: flex;
    gap: 10px;
}

.payment__btn {
    padding-left: 20px;
}

@media only screen and (max-width: 576px) {
    .credit__card__form input::placeholder {
        font-size: 0.9rem;
    }
}